import React from "react"
import type { TagProps } from "@chakra-ui/react"
import { Tag } from "@chakra-ui/react"

import type { Governor, Maybe, VoteStats } from "query/graphql"
import { ProposalStatus } from "query/graphql"
import { wasDefeatedByQuorum } from "proposal/helpers/votes"

function ProposalStatusTag({
  status,
  quorum,
  decimals,
  voteStats,
  timelockId,
  governor,
  ...tagProps
}: {
  status: ProposalStatus
  quorum: string
  decimals: number
  governor: Pick<Governor, "type">
  timelockId: string | null | undefined
  voteStats?: Maybe<VoteStats[]>
} & TagProps) {
  function getLabel({
    status,
    quorum,
    decimals,
    voteStats,
  }: {
    status: ProposalStatus
    quorum: string
    decimals: number
    voteStats?: Maybe<VoteStats[]>
  }) {
    if (status === ProposalStatus.Active) return "Active"
    if (status === ProposalStatus.Extended) return "Active" // TODO(@nicolas): Should it display a different label?
    if (status === ProposalStatus.Pending) return "Pending"
    if (status === ProposalStatus.Expired) return "Expired"
    if (status === ProposalStatus.Defeated) {
      return wasDefeatedByQuorum(governor, quorum, decimals, voteStats)
        ? "Quorum not reached"
        : "Defeated"
    }

    if (
      status === ProposalStatus.Executed ||
      status === ProposalStatus.Crosschainexecuted
    )
      return "Executed"
    if (status === ProposalStatus.Queued) return "Pending execution"
    if (status === ProposalStatus.Pendingexecution) return "Pending execution"
    if (status === ProposalStatus.Draft) return "Draft"

    if (status === ProposalStatus.Canceled) {
      return wasDefeatedByQuorum(governor, quorum, decimals, voteStats)
        ? "Quorum not reached"
        : "Canceled"
    }

    if (status === ProposalStatus.Succeeded) {
      return timelockId ? "Pending queue" : "Pending execution"
    }

    return "Active"
  }

  function getColor(status: ProposalStatus) {
    if (status === ProposalStatus.Active) return "purple.500"
    if (status === ProposalStatus.Extended) return "purple.500"
    if (status === ProposalStatus.Pending) return "purple.500"
    if (status === ProposalStatus.Expired) return "red.500"
    if (status === ProposalStatus.Defeated) return "red.500"
    if (
      status === ProposalStatus.Executed ||
      status === ProposalStatus.Crosschainexecuted
    )
      return "teal.600"
    if (status === ProposalStatus.Succeeded) return "teal.600"
    if (status === ProposalStatus.Queued) return "teal.600"
    if (status === ProposalStatus.Pendingexecution) return "teal.600"
    if (status === ProposalStatus.Canceled) return "gray.500"
    if (status === ProposalStatus.Draft) return "gray.500"

    return "purple.500"
  }

  function getBackground(status: ProposalStatus) {
    if (status === ProposalStatus.Active) return "purple.100"
    if (status === ProposalStatus.Extended) return "purple.100"
    if (status === ProposalStatus.Pending) return "purple.100"
    if (status === ProposalStatus.Expired) return "red.100"
    if (status === ProposalStatus.Defeated) return "red.100"
    if (
      status === ProposalStatus.Executed ||
      status === ProposalStatus.Crosschainexecuted
    )
      return "teal.50"
    if (status === ProposalStatus.Succeeded) return "teal.50"
    if (status === ProposalStatus.Queued) return "teal.50"
    if (status === ProposalStatus.Pendingexecution) return "teal.50"
    if (status === ProposalStatus.Canceled) return "gray.100"
    if (status === ProposalStatus.Draft) return "gray.100"

    return "purple.100"
  }

  return (
    <Tag bg={getBackground(status)} color={getColor(status)} {...tagProps}>
      {getLabel({ status, quorum, decimals, voteStats })}
    </Tag>
  )
}
export default ProposalStatusTag
